import React, {useState, useRef, useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Components/Search.css";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {SearchSchemas} from "../Schemas";
import axios from "../Schemas/Api";
import {toast} from "react-toastify";
import back from "../Assets/Images/Backicon.png";
import {Backdrop, CircularProgress} from "@mui/material";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LoginNavbar from "./LoginNavbar";
import Footer from "./Footer";
const initialValues = {
  Search: "",
  Abults: "",
  Children: "",
  checkInDate: "",
  checkout: "",
};

const Search = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("login"));
  const [searche, setSearch] = useState([]);

  const [loding, setLoding] = useState(false);
  const onSubmit = async () => {
    setLoding(true);
    const formdata = new FormData();
    formdata.append("address", address);
    formdata.append("adult", values.Abults);
    formdata.append("child", values.Children);
    formdata.append("start_date", checkIn);
    formdata.append("end_date", checkout);
    try {
      let Sea = {
        url: "/search_property",
        method: "Post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(Sea)
        .then((res) => res.data)
        .then((respons) => {
          setSearch(respons.data);

          // console.log(respons, "setSearch ka deta ");
          setLoding(false);
          const stateData = {
            mapKaData: {
              Data: checkIn,
              Data2: checkout,
              Datares: respons.data,
            },
            formKaData: {
              address: address,
              adult: values.Abults,
              child: values.Children,
              start_date: checkIn,
              end_date: checkout,
            },
          };
          // console.log(stateData, "chak");
          navigate("/SearchProparty", {
            state: {searchResults: stateData},
          });
        });
    } catch (error) {
      // console.log("userap...", error);
      setLoding(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SearchSchemas,
    onSubmit,
  });

  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [checkIn, setCheckIn] = useState("");
  const [checkout, setCheckout] = useState("");
  const checkInDatePickerRef = useRef(null);
  const checkOutDatePickerRef = useRef(null);
  const [address, setAddress] = useState("");

  const handleCheckInDateChange = (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    setFieldValue("checkInDate", value);
    setCheckInDate(value);
    setCheckIn(formattedDate);
  };

  // console.log(checkIn, "checkIn");
  // console.log(checkout, "checkout");
  const handleCheckOutDateChange = (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    const selectedDate = moment(value);

    if (selectedDate >= checkInDate) {
      setCheckout(formattedDate);
      setFieldValue("checkout", value);
      setCheckOutDate(value);
    } else {
      alert("Check-out date cannot be before check-in date. Please try again.");
    }
  };

  const handleCheckInInputClick = () => {
    if (checkInDatePickerRef.current) {
      checkInDatePickerRef.current.setOpen(true);
    }
  };

  const handleCheckOutInputClick = () => {
    if (checkOutDatePickerRef.current) {
      checkOutDatePickerRef.current.setOpen(true);
    }
  };

  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => navigate(-1)}>
          <img src={back} alt="" />
        </span>
      </div>
      <section className="Search-live-main-div">
        <form onSubmit={handleSubmit} className="">
          <div className="eroor-input">
            <div className="">
              Address
              <PlacesAutocomplete
                value={address}
                onChange={(value) => setAddress(value)}
                // searchOptions={{ types: ["address"] }}
                apiKey={"AIzaSyDJn3lkmc1GoVe1YMv0ZBzpUnLPlKnAeNQ"}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="labul-input">
                    <input
                      {...getInputProps({
                        placeholder: "Enter an address",
                        className: "location-search-input",
                      })}
                      className="input-khud"
                    />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.length > 0 && (
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                className="addressdropdown"
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </div>

          <div className="mt-4">
            <span>Abults</span>
            <div className="svg-p-div">
              <input
                className="search-div-live-ka-bad"
                type="number"
                id="gsearch"
                placeholder="Abults"
                name="Abults"
                value={values.Abults}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Abults && touched.Abults ? (
              <p className="mastu-to-eroor mt-2">{errors.Abults} </p>
            ) : null}
          </div>
          <div className="mt-4">
            <span>Children (Ages 1-12)</span>
            <div className="svg-p-div">
              <input
                className="search-div-live-ka-bad"
                type="number"
                id="gsearch"
                placeholder="Children"
                name="Children"
                value={values.Children}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Children && touched.Children ? (
              <p className="mastu-to-eroor mt-2">{errors.Children} </p>
            ) : null}
          </div>
          <div className="mt-4">
            <span>Check in</span>
            <div className="svg-p-div ">
              <svg
                className="Check-in-svg "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_67_949)">
                  <path
                    d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                    fill="#9C04F5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_67_949">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <DatePicker
                className="svg-search-div-live-ka-bad"
                selected={checkInDate}
                onChange={handleCheckInDateChange}
                startDate={checkInDate}
                endDate={checkOutDate}
                value={checkIn}
                minDate={new Date()}
                onBlur={handleBlur}
                selectsStart
                name="checkInDate"
                customInput={
                  <input
                    onClick={handleCheckInInputClick}
                    placeholder="Select check-in date"
                  />
                }
                placeholderText="Enter check-in date"
                ref={checkInDatePickerRef}
              />
            </div>
            {errors.checkInDate && touched.checkInDate ? (
              <p className="mastu-to-eroor mt-2">{errors.checkInDate}</p>
            ) : null}
          </div>

          <div className="mt-4">
            <span>Check out</span>
            <div className="svg-p-div ">
              <svg
                className="Check-in-svg "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_67_949)">
                  <path
                    d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                    fill="#9C04F5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_67_949">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <DatePicker
                className="svg-search-div-live-ka-bad"
                selected={checkOutDate}
                onChange={handleCheckOutDateChange}
                startDate={checkInDate}
                endDate={checkOutDate}
                value={checkout}
                onBlur={handleBlur}
                name="checkout"
                minDate={new Date()}
                selectsEnd
                customInput={
                  <input
                    onClick={handleCheckOutInputClick}
                    placeholder="Select check-out date"
                  />
                }
                placeholderText="Enter check-out date"
                ref={checkOutDatePickerRef}
              />
            </div>
            {errors.checkout && touched.checkout ? (
              <p className="mastu-to-eroor mt-2">{errors.checkout}</p>
            ) : null}
            <div className="mt-4">
              <button type="submit" className="Search-live-ke-bad-btn">
                {loding ? "Loding" : "Search"}
              </button>
            </div>
          </div>
        </form>
      </section>
      <Footer />
      {!loding && <div></div>}
      {loding && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Search;
