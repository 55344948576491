import React, { useState, useEffect, useCallback } from "react";
import "../Components/AddProperty.css";
import Pluse from "../Assets/Images/Addbutn.png";
import Button from "react-bootstrap/Button";
// import Malt1 from "../Assets/Images/Malt1.png";
// import Malt2 from "../Assets/Images/Malt2.png";
// import Malt3 from "../Assets/Images/Msalt3.png";
import Mal from "../Assets/Images/Maltepalplus.png";
import { useFormik } from "formik";
import { EditSchemas } from "../Schemas";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import LoginNavbar from "../Components/LoginNavbar";
import Footer from "../Components/Footer";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
import plus from "../Assets/Images/plus.svg";
import remove from "../Assets/Images/delete.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  Airconditioning,
  BathoomEssentials,
  BedroomEssentials,
  CheckinMethod,
  Children,
  Families,
  Gardenview,
  Kitchen,
  Kitchenette,
  Security,
  Sharespace,
  StepFree,
  HouseRules,
} from "../Components/Checkboxdata";
import { calcLength } from "framer-motion";

const initialValues = {
  title: "",
  city: "",
  state: "",
  price: "",
  category: "",
  bedsheetsothors: "",
  aircondiothors: "",
  checkinMethodothors: "",
  kitchanothors: "",
  childothors: "",
  leanFessothors: "",
  bedroomsothors: "",
  bathroomothars: "",
  Size: "",
  sizeunety: "",
  fname: "",
  Maximum: "",
  Maximumc: "",
  Neighborhood: "",
  Property: "",
  policy: "",
  // type_of_place: "",
  // type_of_property: "",
};
// console.log(initialValues, "jhduh");
const EditProperty = () => {
  const Maines = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11H13H17V13H13V13H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
        fill="black"
      />
    </svg>
  );

  const [Getcategory, setGetcategory] = useState([]);

  // const [Isoterhbeds, setIsoterhbeds] = useState(false);
  // const [Isoterhbeds, setIsoterhbeds] = useState(
  //   !!Detail.other_bedroom_essentials
  // );

  const [IsotherCheckMethod1, setIsotherCheckMethod1] = useState(false);

  const [IsOtherCleanFess, setIsOtherCleanFess] = useState(false);
  const [OtherClaenFees, setOtherClaenFees] = useState("");
  const [IsotherKichen, setIsotherKichen] = useState(false);
  const [IsotherHouseRules, setIsotherHouseRules] = useState(false);
  const [IsotherKichen1, setIsotherKichen1] = useState(false);
  const [sharespace1, setSharespace1] = useState(false);
  const [House, setHouse] = useState(HouseRules);

  //usesssssssssssssssssss
  const [sharespace, setsharespace] = useState(Sharespace);
  const [kitchenette, setketKitchenette] = useState(Kitchenette);
  const [kitchan, setkitchan] = useState(Kitchen);
  const [stepFree, setStepFree] = useState(StepFree);
  const [checkinMethod, setCheckinMethod] = useState(CheckinMethod);
  const [gardenview, setGardenview] = useState(Gardenview);
  const [security, setSecurity] = useState(Security);
  const [Dispose, setDispose] = useState(false);
  const [lod, setLod] = useState(false);
  const token = JSON.parse(localStorage.getItem("login"));
  // Time usestat----------------------------------

  // Time usestat----------------------------------No noise after
  const [selectedCheckBox, setselectedCheckBox] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [Model, setModel] = useState(false);
  const [free, setFree] = useState(false);
  const [cleningFee, setCleningFee] = useState();
  const [numBedrooms, setNumBedrooms] = useState(1);
  const [numBathrooms, setNumBathrooms] = useState(1);
  const [bedrooms, setBedrooms] = useState([{ id: 1, beds: [{ id: 1, type: "", qty: 1 }] }]);
  const [bathrooms, setBathrooms] = useState([{ id: 1, beds: [{ id: 1, type: "", qty: 1 }] }]);

  const bedTypes = ["Double bed", "Single bed", "Baby crib", "Bunk bed", "Queen bed", "King bed"];
  const bathroomTypes = ["Shower", "Bathtub", "Hydromassage Shower", "Bathtub with shower"];

  const generateBedrooms = (num) => {
    const newBedrooms = [];
    for (let i = 0; i < num; i++) {
      newBedrooms.push({
        id: i + 1,
        beds: [{ id: 1, type: "", qty: 1 }]
      });
    }
    setBedrooms(newBedrooms);
  };

  const generateBathrooms = (num) => {
    const newBathrooms = [];
    for (let i = 0; i < num; i++) {
      newBathrooms.push({
        id: i + 1,
        beds: [{ id: 1, type: "", qty: 1 }]
      });
    }
    setBathrooms(newBathrooms);
  };

  const addBed = (roomId, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: [...room.beds, { id: room.beds.length + 1, type: "", qty: 1 }]
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const removeBed = (roomId, bedId, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.filter(bed => bed.id !== bedId)
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const handleBedTypeChange = (roomId, bedId, type, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.map(bed => bed.id === bedId ? { ...bed, type } : bed)
          };
        }
        return room;
      });
      return newRooms;
    });
  };

  const handleBedQtyChange = (roomId, bedId, qty, isBathroom = false) => {
    const setter = isBathroom ? setBathrooms : setBedrooms;
    setter(prevRooms => {
      const newRooms = prevRooms.map(room => {
        if (room.id === roomId) {
          return {
            ...room,
            beds: room.beds.map(bed => bed.id === bedId ? { ...bed, qty } : bed)
          };
        }
        return room;
      });
      return newRooms;
    });
  };
  const handleCheckboxChange = (val) => {
    // console.log(val);
    setselectedCheckBox(selectedCheckBox == val ? "" : val);

    if (val === "0" && free === true) {
      // setShowInput(!showInput);
      setModel(true);
    } else if (val === "1" && free === false) {
      setShowInput(!showInput);
    }
  };

  const Popup = (props) => {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
            
          </Modal.Header> */}
          <div className="Property-card-divv-ppx">
            <div className="Property-ppx-cvv mt-2">
              <span className="spandivv-ppx-property">
                Are you sure you want to log out?
              </span>
            </div>

            {/* <div className="Payment-divv-section">
              <div className="Payment-divv-sec-mid"></div>
            </div> */}

            <div className="Button-divv-property">
              <div className="btnn-divv-pro-later">
                <button
                  onClick={() => setModel(false)}
                  className="btn-pro-later-main"
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  // end/////////////////////////////////

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lan", position.coords.longitude);
    });
  }, []);
  const getcategory = async () => {
    const formdata = new FormData();
    // formdata.append("property_id", id);
    try {
      let cofe = {
        url: "/get_category",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(cofe)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons);
          setGetcategory(respons.category_list);
        });
    } catch (error) {
      // console.log("userap...", error);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    getcategory();
  }, []);

  // Api

  let route = useLocation();
  const addpropdata = route.state.id;
  // console.log(addpropdata);
  // console.log(addpropdata.image);
  // console.log(addpropdata.property_data.id);
  // console.log(
  //   addpropdata.property_data.id.other_bedroom_essentials,
  //   "route data"
  // );

  // const [Detail, setDetail] = useState([]);
  const [Detail, setDetail] = useState({});
  // console.log(Detail, "Detail");
  // console.log(Detail.other_kitchen_essentials, "kitchen value");
  const [IsotherView, setIsotherView] = useState(!!Detail.other_view);

  // const [airconditioning, setAirconditioning] = useState(false);
  const [airconditioning, setAirconditioning] = useState(
    !!Detail.other_airconditional_essentials
  );

  // const [IsotherCheckMethod, setIsotherCheckMethod] = useState(false);
  const [IsotherCheckMethod, setIsotherCheckMethod] = useState(
    !!Detail.other_checkin_essentials
  );

  // const [IsotherChildern, setIsotherChildern] = useState(false);
  const [IsotherChildern, setIsotherChildern] = useState(
    !!Detail.other_baby_essentials
  );

  // const [IsotherBathRooms, setIsotherBathRooms] = useState(false);
  const [IsotherBathRooms, setIsotherBathRooms] = useState(
    !!Detail.other_bathroom_essentials
  );

  const [Isoterhbeds, setIsoterhbeds] = useState(
    !!Detail.other_bedroom_essentials
  );

  const [Images, setImages1] = useState([]);

  const [valueee, onChangee] = useState();
  const [out, onOut] = useState();
  const [noise, onNoise] = useState();
  const [pool, onPool] = useState();
  const [tubb, onTub] = useState();

  // console.log(Images, "Images");
  // let Ameteis = Detail.amenities.map((item) => {
  //   return item.amenity;
  // });
  // console.log(Ameteis, "An");

  // console.log(Detail, "yuiopqazxcvbnm");
  // console.log(addpropdata.property_data.id);

  const Propertydetail = async () => {
    const formdata = new FormData();
    formdata.append("property_id", addpropdata.property_data.id);

    try {
      let Prop = {
        // url: "https://admin.homevr.online/property_detail",
        url: "/property_detail_for_edit",
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(Prop)
        .then((res) => res.data)
        .then((respons) => {
          // console.log("Lassan", respons);
          setDetail(respons.data.property_detail);
          onChangee(respons.data.property_detail.checkin_time);
          onOut(
            respons.data.property_detail.checkout_time == "undefined"
              ? null
              : respons.data.property_detail.checkout_time
          );

          onNoise(
            respons.data.property_detail.no_noise_after == "undefined"
              ? null
              : respons.data.property_detail.no_noise_after
          );
          onPool(
            respons.data.property_detail.swimming_pool_time == "undefined"
              ? null
              : respons.data.property_detail.swimming_pool_time
          );
          onTub(
            respons.data.property_detail.hot_tub_time == "undefined"
              ? null
              : respons.data.property_detail.hot_tub_time
          );
          setImages1(respons.data.images);
          // console.log(
          //   "Sakir bhai ka data yaha mil ta hai ",
          //   respons.data.property_detail.title
          // );
          setFieldValue(
            "title",
            respons.data.property_detail.title
              ? respons.data.property_detail.title
              : "wasd"
          );
        });
    } catch (error) {
      // console.log("userap...", error);
    }
  };
  useEffect(() => {
    Propertydetail();
  }, []);

  // end-gat-proparty-----------------------

  const [Policies, setPolicies] = useState([]);
  const [Loading, setLoading] = useState(false);
  // console.log(Policies);
  const Policy = async () => {
    const formdata = new FormData();

    setLoading(true);
    try {
      let poly = {
        url: "/get_cancellation_policies",
        method: "Get",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };
      axios(poly)
        .then((res) => res.data)
        .then((respons) => {
          setPolicies(respons.data);
          setLoading(false);
        });
    } catch (error) {
      // console.log("userap...", error);
      setLoading(false);
      toast.error(error.response.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    Policy();
  }, []);
  //add item
  const [efor, setEfor] = useState();
  // console.log(efor, "sakir rr");
  const [How, setHow] = useState([]);
  // console.log(addpropdata.property_data.id, "sakir id");
  // console.log({ efor }, addpropdata.property_data.id);
  // console.log(token.data.token.token, "sakir token");
  const Editfor = async () => {
    const formdata = new FormData();
    formdata.append("property_id", addpropdata.property_data.id);
    setLod(true);
    try {
      let param = {
        url: "/property_detail_for_edit",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          authorization: `${token.data.token.token}`,
        },
      };

      axios(param)
        .then((res) => res.data)
        .then((respons) => {
          // console.log(respons, "qazxcvb");
          setEfor(respons);
          setHow(respons?.property_detail?.house_rules);
          setLod(false);
        })
        .catch((err) => {
          // console.log("🚀 ~ file: EditProperty.js:273 ~ .then ~ err:", err);
          setLod(false);
        });
    } catch (error) {
      // console.log("userap...", error);
      setLod(false);
    }
  };
  useEffect(() => {
    Editfor();
  }, []);
  // console.log(How, "howhow");
  const [fields, setFields] = useState([{ value: "", id: 0 }]);
  const [fields2, setFields2] = useState([{ value: "", id: 0 }]);
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  // console.log(fields2,"kamil")

  // console.log({ fields });


  const Navigat = useNavigate();
  const [loading, setloading] = useState(false);
  const [images, setImages] = useState([]);
  // const [image, setImage] = useState([
  // ]);
  const [lat, setlat] = useState(null);
  const [address, setAddress] = useState("");
  const [BedRooms, setBedRooms] = useState(BedroomEssentials);
  const [policyid, setpolicyid] = useState("");
  const [Bathroom, setBathroom] = useState(BathoomEssentials);
  const [Aircondi, setAircondi] = useState(Airconditioning);
  // const [Checkin, Setcheckin] = useState(CheckinMethod);
  const [Child, setchild] = useState(Children);
  const [Familie, setfamilie] = useState(Families);

  // var maindata = [stepFree,BedRooms, Bathroom, Aircondi, Child, security, sharespace, Familie, gardenview]
  // var maindata = [...stepFree,...BedRooms,... Bathroom,... Aircondi,... Child,... security,... sharespace,... Familie,... gardenview]
  const onSubmit = async () => {
    setLoading(true);
    setLod(true);
    const formdata = new FormData();
    BedRooms.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    stepFree.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Bathroom.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Aircondi.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Child.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    security.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    kitchan.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    kitchenette.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    sharespace.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    Familie.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    gardenview.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    checkinMethod.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });
    House.map((item) => {
      formdata.append(item.backEndName, item.isSelected == true ? 1 : 0);
    });

    formdata.append("type_of_property", "safa");
    formdata.append("type_of_place", "jna");
    formdata.append("cleaning_fees", values.leanFessothors);
    formdata.append("other_airconditioning_essentials", values.aircondiothors);
    formdata.append("other_bathroom_essentials", values.bathroomothars);
    formdata.append("other_bedroom_essentials", values.bedroomsothors);
    formdata.append("other_kitchen_essentials", values.kitchanothors);
    formdata.append("other_baby_essentials", values.childothors);
    formdata.append("other_checkin_essentials", values.checkinMethodothors);
    formdata.append("other_amenities", values.amenities);
    // console.log(values.amenities, "values.amenities");
    formdata.append("other_view", values.gardenviewothors);
    formdata.append("neighbourhood_description", values.Neighborhood);
    formdata.append("property_size", values.Size);
    formdata.append("property_size_unit", values.sizeunety);
    formdata.append("", values.sizeunety);

    for (var pair of formdata.entries()) {
      // console.log(pair[0], pair[1]);
      // console.log(formdata.entries().next().value);
    }
    formdata.append("title", values.title);
    formdata.append("latitude", localStorage.getItem("lat"));
    formdata.append("longitude", localStorage.getItem("lan"));
    formdata.append("address", address);
    formdata.append("city", values.city);
    formdata.append("state", values.state);
    formdata.append("zipcode", values.zipcode);
    formdata.append("guest_space", values.guest_space);
    formdata.append("bedrooms", numBedrooms);
    formdata.append("beds", JSON.stringify(bedrooms));
    formdata.append("adult", values.Maximum);
    formdata.append("child", values.Maximumc);
    formdata.append("free_cleaning_service", selectedCheckBox ? 1 : 0);
    formdata.append("cleaning_fees", cleningFee);
    formdata.append("bathrooms", JSON.stringify(bathrooms));
    formdata.append("checkin_time", valueee);
    formdata.append("checkout_time", out);
    formdata.append("no_noise_after", noise);
    formdata.append("swimming_pool_time", pool);
    formdata.append("hot_tub_time", tubb);
    formdata.append("house_rules", JSON.stringify(fields2));
    // formdata.append("house_rules", JSON.stringify([{ id: 133, value: "Lorem ipsum dolor amit" },{ id: 1, value: "Lorem ipsum dolor amit" },]));
    formdata.append("price_per_night", values.price);
    formdata.append("category_id", values.category);
    formdata.append("cancellation_policy_id", policyid);
    formdata.append("neighbourhood_description", values.Neighborhood);
    formdata.append("about_property", values.Property);
    formdata.append("property_id", addpropdata.property_data.id);
    formdata.append("leave_bedsheets_and_towels", values.Specify);
    // console.log(values.Specify);

    formdata.append("type_of_place", "Private");
    for (let i = 0; i < images.length; i++) {
      formdata.append("images", images[i]);
    }
    try {
      const res = await axios({
        url: "/edit_property",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `${token?.data?.token?.token}`,
          // authorization:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTE5LCJleHAiOjE3MTUxMDM2NTV9.wPMGYdBuDRMGSOvoWfZ69KXS4MGf-7D6S002JJhQeGU",
        },
      })
        .then((res) => res.data)
        .then((data) => {
          // console.log(data, "durrrrr seeee");
          setLod(false);
          if (data.message === "Property Updated Successfully") {
            Navigat("/MyProfile/MyProperty");
          }
          if (data.status === 1) {
            toast.success(data.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (data.status === 0) {
            toast.error(data.message, {
              position: "top-right",
              theme: "colored",
            });
          }
        });

      // console.log("resss...", res);
      // console.log(res);
    } catch (error) {
      // console.log("HJGHGH", error);
      setLod(false);
    }
  };

  // ADD Api

  const {
    handleBlur,
    handleChange: handle,
    handleSubmit,
    touched,
    errors,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditSchemas,
    onSubmit,
  });
  // console.log(values.kitchanothors, "kitchan others");

  useEffect(() => {
    setFieldValue("adult", Detail.Maximum);
    setFieldValue("child", Detail.Maximumc);
    setFieldValue("title", Detail.title);
    setFieldValue("address", Detail.address);
    setFieldValue("city", Detail.city);
    setFieldValue("state", Detail.state);
    setFieldValue("zipcode", Detail.zipcode);
    setFieldValue("bedrooms", Detail.bedrooms);
    setFieldValue("beds", Detail.bedrooms);
    setFieldValue("Maximum", Detail.adult);
    setFieldValue("Maximumc", Detail.child);
    setFieldValue("Bathrooms", Detail.bathroom);
    setFieldValue("price", Detail.price_per_night);
    setFieldValue("category", Detail.category_id);
    setFieldValue("bedsheetsothors", Detail.fullname);
    setFieldValue("aircondiothors", Detail.other_airconditional_essentials);
    setFieldValue("checkinMethodothors", Detail.other_checkin_essentials);
    setFieldValue("kitchanothors", Detail.other_kitchen_essentials);
    setFieldValue("childothors", Detail.other_baby_essentials);
    setFieldValue("leanFessothors", Detail.fullname);
    setFieldValue("bedroomsothors", Detail.other_bedroom_essentials);
    // console.log(Detail.other_bedroom_essentials, "setfield value of other");
    setFieldValue("bathroomothars", Detail.other_bathroom_essentials);
    setFieldValue("amenities", Detail.amenities);
    setFieldValue("Size", Detail.property_size);
    setFieldValue("sizeunety", Detail.property_size_unit);
    setFieldValue("gardenviewothors", Detail.other_view);
    setFieldValue("Neighborhood", Detail.neighbourhood_description);
    setFieldValue("Property", Detail.about_property);
    setFieldValue("policy", Detail.cancellation_policy);
    setFieldValue("Specify", Detail.leave_bedsheet_and_towels);
    setAddress(Detail.address);
    setselectedCheckBox(Detail.selectedCheckBox ? 1 : 0);
  }, [Detail]);

  // console.log(selectedCheckBox, "selectedCheckBox sakir");
  // console.log(values.title, "akakak");

  const [imageCount, setImageCount] = useState(0);
  const handleImageChange = (event) => {
    // setImages(event.target.files[0])
    const selectedImages = Array.from(event.target.files);

    if (imageCount + selectedImages.length <= 15) {
      setImages([...images, ...selectedImages]);
      setImageCount(imageCount + selectedImages.length);
    } else {
      alert(
        `You can only select a maximum of 15 images. You tried to select ${selectedImages.length} images.`
      );
    }
  };

  const handimgleDelete = (index) => {
    setImages(images.filter((image, i) => i !== index));
    setImageCount(imageCount - 1);
  };

  const handleAPIimgDelete = async (imgID) => {
    setLoading(true);
    try {
      const res = await axios.post(
        { url: "/delete_image" },
        { image_id: imgID },
        { headers: { authorization: `${token.data.token.token}` } }
      );
      // console.log(imgID, "imgID uguhgu MNmnn");
      // console.log(res);
      if (res) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  // malty

  const [val, setval] = useState([]);

  // const addEvent = () => {
  //   const abc = [...val, []];
  //   setval(abc);
  // };

  // console.log(val);
  // const handleChange = (onChangeValue, i) => {
  //   const inputData = [...val];
  //   inputData[i] = onChangeValue.target.value;
  //   setval(inputData);
  // };

  const [checkboxdata, setcheckboxdata] = useState([]);

  const handlechange = (event) => {
    const value = event.target.checked;
    console.log(value);
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }

    // console.log(event.target.checked);
  };

  const handleDelete = (i) => {
    const detVel = [...val];
    // console.log("====================================");
    // console.log(val);
    // console.log("====================================");
    detVel.splice(i, 1);
    setval(detVel);
  };

  // ------

  const [mal, setMal] = useState([]);

  const addEven = () => {
    const abc = [...mal, []];
    setMal(abc);
  };

  const handleChang = (onChangeValue, e) => {
    const inputData = [...mal];
    inputData[e] = onChangeValue.target.value;
    setMal(inputData);
  };

  const handleDelet = (e) => {
    const detMal = [...mal];
    // console.log("====================================");
    // console.log(val);
    // console.log("====================================");
    detMal.splice(e, 1);
    setMal(detMal);
  };
  // console.log(Detail, "oxoxoxxo");

  // useEffect(() => {
  //   if (Detail.amenities) {
  //     let arr = [];
  //     BedRooms.map((item) => {
  //       Detail.amenities.map((_item) => {
  //         console.log(_item);
  //         if (item.backEndName == _item.amenity) {
  //           arr = [...arr, { ...item, isSelected: true }];
  //         } else {
  //         }
  //       });
  //     });

  //     setBedRooms(arr);
  //   }
  // }, [Detail]);
  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = BedRooms.map((bedroom) => {
        const isSelected = selectedAmenities.includes(bedroom.backEndName);
        return { ...bedroom, isSelected };
      });
      setBedRooms(updatedBedrooms);
    }

    // if (Detail.amenities) {
    //   const selectedAmenities = Detail.amenities.map(
    //     (amenity) => amenity.amenity
    //   );
    //   const updatedBedrooms = Bathroom.map((bedroom) => {
    //     const isSelected = selectedAmenities.includes(bedroom.backEndName);
    //     return { ...bedroom, isSelected };
    //   });
    //   setBathroom(updatedBedrooms);
    // }

    // if (Detail.amenities) {
    //   const selectedAmenities = Detail.amenities.map(
    //     (amenity) => amenity.amenity
    //   );
    //   const updatedBedrooms = Aircondi.map((bedroom) => {
    //     const isSelected = selectedAmenities.includes(bedroom.backEndName);
    //     return { ...bedroom, isSelected };
    //   });
    //   setAircondi(updatedBedrooms);
    // }
    // if (Detail.amenities) {
    //   const selectedAmenities = Detail.amenities.map(
    //     (amenity) => amenity.amenity
    //   );
    //   const updatedBedrooms = Child.map((bedroom) => {
    //     const isSelected = selectedAmenities.includes(bedroom.backEndName);
    //     return { ...bedroom, isSelected };
    //   });
    //   setchild(updatedBedrooms);
    // }
    // if (Detail.amenities) {
    //   const selectedAmenities = Detail.amenities.map(
    //     (amenity) => amenity.amenity
    //   );
    //   const updatedBedrooms = Familie.map((bedroom) => {
    //     const isSelected = selectedAmenities.includes(bedroom.backEndName);
    //     return { ...bedroom, isSelected };
    //   });
    //   setfamilie(updatedBedrooms);
    // }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = Bathroom.map((Bathroom) => {
        const isSelected = selectedAmenities.includes(Bathroom.backEndName);
        return { ...Bathroom, isSelected };
      });
      setBathroom(updatedBedrooms);
    }
  }, [Detail]);
  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = Aircondi.map((Aircondi) => {
        const isSelected = selectedAmenities.includes(Aircondi.backEndName);
        return { ...Aircondi, isSelected };
      });
      setAircondi(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = Child.map((Child) => {
        const isSelected = selectedAmenities.includes(Child.backEndName);
        return { ...Child, isSelected };
      });
      setchild(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = Familie.map((Familie) => {
        const isSelected = selectedAmenities.includes(Familie.backEndName);
        return { ...Familie, isSelected };
      });
      setfamilie(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = sharespace.map((sharespace) => {
        const isSelected = selectedAmenities.includes(sharespace.backEndName);
        return { ...sharespace, isSelected };
      });
      setsharespace(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = kitchan.map((kitchan) => {
        const isSelected = selectedAmenities.includes(kitchan.backEndName);
        return { ...kitchan, isSelected };
      });
      setkitchan(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = stepFree.map((stepFree) => {
        const isSelected = selectedAmenities.includes(stepFree.backEndName);
        return { ...stepFree, isSelected };
      });
      setStepFree(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = checkinMethod.map((checkinMethod) => {
        const isSelected = selectedAmenities.includes(
          checkinMethod.backEndName
        );
        return { ...checkinMethod, isSelected };
      });
      setCheckinMethod(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = gardenview.map((gardenview) => {
        const isSelected = selectedAmenities.includes(gardenview.backEndName);
        return { ...gardenview, isSelected };
      });
      setGardenview(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = security.map((security) => {
        const isSelected = selectedAmenities.includes(security.backEndName);
        return { ...security, isSelected };
      });
      setSecurity(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.amenities) {
      const selectedAmenities = Detail.amenities.map(
        (amenity) => amenity.amenity
      );
      const updatedBedrooms = kitchenette.map((kitchenette) => {
        const isSelected = selectedAmenities.includes(kitchenette.backEndName);
        return { ...kitchenette, isSelected };
      });
      setketKitchenette(updatedBedrooms);
    }
  }, [Detail]);

  useEffect(() => {
    if (Detail.house_rules) {
      const selectedAmenities = Detail.house_rules.map(
        (amenity) => amenity.house_rule
      );
      const updatedBedrooms = House.map((House) => {
        const isSelected = selectedAmenities.includes(House.backEndName);
        return { ...House, isSelected };
      });
      setHouse(updatedBedrooms);
    }
  }, [Detail]);

  // console.log(Detail.house_rules, "safa");
  useEffect(() => {
    if (Detail.cancellation_policy) {
      // const selectedAmenities = Detail.cancellation_policy.map(
      //   (amenity) => amenity.policy
      // );
      // const updatedBedrooms = Policies.map((Policies) => {
      //   const isSelected = selectedAmenities.includes(Policies.policy);
      //   return { ...Policies, isSelected };
      // });
      // setPolicies(updatedBedrooms);
      setpolicyid(Detail.cancellation_policy.id);
    }
  }, [Detail]);
  // console.log(Detail.swimming_pool_time);
  // console.log(policyid);

  // const setData = () => {checkinMethod
  //   const newBedRoom = BedRooms.map((item) => {
  //     for (const key in Detail.amenities) {
  //       console.log(key, "keys");
  //       if (item.backEndName == key) {
  //         return { ...item, isSelected: Ameteis[key] };
  //       }
  //     }
  //   });
  //   const NewBathRooms = Bathroom.map((item) => {
  //     for (const key in Ameteis) {
  //       if (item.backEndName == key) {
  //         return { ...item, isSelected: Ameteis[key] };
  //       }

  //     }
  //   });
  //   const NewAircondisar = Aircondi.map((item) => {
  //     for (const key in Ameteis) {
  //       if (item.backEndName == key) {
  //         return { ...item, isSelected: Ameteis };
  //       }
  //     }
  //   });
  // console.log(newBedRoom);

  // setBedRooms(newBedRoom);
  // setBathroom(NewBathRooms);
  // setAircondi(NewAircondisar);

  // useEffect(() => {
  //   setData();
  // }, []);

  const handleSelect = async (value, { description }) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    // console.log(latLng);
    setlat(latLng);
    setAddress(value);
  };

  return (
    <>
      <LoginNavbar />
      {Model && <Popup show={Model} onHide={() => setModel(false)} />}
      <section className="AddProperty-main-section">
        <div className="AddProperty-main-div">
          <form onSubmit={handleSubmit} className="selact-tag-div">
            <div className="Add-Property-div mb-4">
              <span className="add-propaty-span"> Edit Property</span>
            </div>
            <div className="mb-4">
              <span className="Basic-Details-span">Basic Details</span>
            </div>
            <div className="main-input-div">
              <div className="eroor-input">
                <div className="labul-input ">
                  <label for="fname">Title</label>
                  <input
                    className="input-khud"
                    type="text"
                    id="fname"
                    name="title"
                    value={values.title}
                    placeholder="Title"
                    onBlur={handleBlur}
                    onChange={handle}
                  />
                </div>
                {errors.title && touched.title ? (
                  <p className="form-errror">{errors.title} </p>
                ) : null}
              </div>
              <div className="eroor-input">
                <div className="">
                  Address
                  <PlacesAutocomplete
                    value={address}
                    onChange={(value) => setAddress(value)}
                    onSelect={(value, suggestion) =>
                      handleSelect(value, suggestion)
                    }
                    searchOptions={{ types: ["address"] }}
                    apiKey={"AIzaSyDJn3lkmc1GoVe1YMv0ZBzpUnLPlKnAeNQ"}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="labul-input">
                        <input
                          {...getInputProps({
                            placeholder: "Enter an address",
                            className: "location-search-input",
                          })}
                          className="input-khud"
                        />
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    className="addressdropdown"
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="span-selact-rtag">
                <span>City</span>
                <input
                  className="input-khud"
                  value={values.city}
                  type="text"
                  id="fname"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handle}
                />
                {errors.city && touched.city ? (
                  <p className="form-errror">{errors.city} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag mt-4">
                <span>State</span>
                <input
                  value={values.state}
                  name="state"
                  onBlur={handleBlur}
                  onChange={handle}
                  className="input-khud"
                  type="text"
                />

                {errors.state && touched.state ? (
                  <p className="form-errror">{errors.state} </p>
                ) : null}
              </div>
              <div className="labul-input mt-4">
                <label for="fname">Zipcode</label>
                <input
                  className="input-khud"
                  type="number"
                  id="fname"
                  name="zipcode"
                  value={values.zipcode}
                  placeholder="Zipcode"
                  onBlur={handleBlur}
                  onChange={handle}
                />
                {/* {errors.zipcode && touched.zipcode ? (
                  <p className="form-errror">{errors.zipcode} </p>
                ) : null} */}
              </div>
              <div className="span-selact-rtag mt-4">
                <span>Category</span>
                <div className="city-select-section">
                  <select
                    name="category"
                    onBlur={handleBlur}
                    value={values.category}
                    onChange={handle}
                    className="opan-div"
                  >
                    <option value="">Select Category</option>
                    {Getcategory?.map((itm, index) => {
                      return (
                        <option key={index} value={itm.id}>
                          {itm.title}
                        </option>
                      );
                    })}
                    ;
                  </select>
                </div>
                {errors.category && touched.category ? (
                  <p className="form-errror">{errors.category} </p>
                ) : null}
              </div>
            </div>
            <div className="Maximum-Accommodate-main-div">
              <div className="span-selact-rtag mt-4">
                <span>Maximum Adults Proparty Can Accommodate</span>
                <div className="Maximum-selact-main mt-2">
                  <select
                    name="Maximum"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.Maximum}
                    className="Maximum-div-selact-tag"
                  >
                    <option value="0"></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                {errors.Maximum && touched.Maximum ? (
                  <p className="form-errror">{errors.Maximum} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag mt-4">
                <span>Maximum Children Proparty Can Accommodate</span>
                <div className="Maximum-selact-main mt-2">
                  <select
                    name="Maximumc"
                    onBlur={handleBlur}
                    onChange={handle}
                    value={values.Maximumc}
                    className="Maximum-div-selact-tag-dusra"
                  >
                    <option value="0"></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                {errors.Maximumc && touched.Maximumc ? (
                  <p className="form-errror">{errors.Maximumc} </p>
                ) : null}
              </div>
            </div>
            <div className="Add-Property-div mt-5">
              <span className="add-propaty-span">Property Details</span>
            </div>
            <div className="main-input-div-selact mt-4">
              <div className="mapmathard">
                <span className="night-gold">Price per night</span>
                <div className="doller-sghin">
                  <span>€</span>
                  <input
                    className="input-khud-thard"
                    type="number"
                    id="fname"
                    name="price"
                    value={values.price}
                    // placeholder="$"
                    min="0"
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                    onBlur={handleBlur}
                    onChange={handle}
                  />
                </div>
                {errors.price && touched.price ? (
                  <p className="form-errror">{errors.price} </p>
                ) : null}
              </div>
              <div className="span-selact-rtag">
                <span>Property Size unit</span>
                <div className="city-select-thard-section">
                  <select
                    name="sizeunety"
                    onBlur={handleBlur}
                    value={values.sizeunety}
                    onChange={handle}
                    className="opan-div-selact-tag"
                  >
                    <option value="0">Property Size unit</option>
                    <option value="1"> Sq mt </option>
                    <option value="2">Sq ft</option>
                  </select>
                </div>
                {errors.sizeunety && touched.sizeunety ? (
                  <p className="form-errror">{errors.sizeunety} </p>
                ) : null}
              </div>
              <div className="mapmathard">
                <label for="fname">Property Size</label>
                <div className="doller-sghin">
                  <input
                    className="input-khud-thard"
                    type="number"
                    id="fname"
                    name="Size"
                    value={values.Size}
                    placeholder="Property Size"
                    onBlur={handleBlur}
                    onChange={handle}
                  />
                </div>
                {errors.Size && touched.Size ? (
                  <p className="form-errror">{errors.Size} </p>
                ) : null}
              </div>
            </div>
            <div>
              <div className="mt-3">
                <span className="numBedrooms">Bedrooms:</span>
              </div>
              <div className="city-select-thard-section">
                <select
                  className="opan-div-selact-tag"
                  id="numBedrooms"
                  value={numBedrooms}
                  onChange={(e) => {
                    setNumBedrooms(parseInt(e.target.value));
                    generateBedrooms(parseInt(e.target.value));
                  }}
                >
                  {[...Array(11).keys()].slice(1).map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>
              <div id="bedroomsContainer">
                {bedrooms.map(bedroom => (
                  <div key={bedroom.id} style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <h6>Bedroom {bedroom.id}</h6>
                    {bedroom.beds.map((bed, index) => (
                      <div key={bed.id} className="Select_merge_divv mt-3">
                        <div className="city-select-thard-section">
                          <select
                            value={bed.type}
                            onChange={(e) => handleBedTypeChange(bedroom.id, bed.id, e.target.value)}
                            className="opan-div-selact-tag"
                          >
                            <option value="" disabled>Select Bed Type</option>
                            {bedTypes.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="city-select-thard-section">
                          <select
                            value={bed.qty}
                            onChange={(e) => handleBedQtyChange(bedroom.id, bed.id, parseInt(e.target.value))}
                            className="opan-div-selact-tag"
                          >
                            <option value="" disabled>Select Quantity</option>
                            {[...Array(11).keys()].slice(1).map(num => (
                              <option key={num} value={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                        {index !== 0 && (
                          <button
                            type="button"
                            onClick={() => removeBed(bedroom.id, bed.id)}
                            className="Del_expertise_btnn"
                          >
                            <img src={remove} alt="Remove" />
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addBed(bedroom.id)}
                      className="Add_expertise_btnn mt-2"
                    >
                      <div className="button_plus_divv">
                        <img src={plus} alt="Add" />
                        <span className="Adding_more">Add Bed</span>
                      </div>
                    </button>
                  </div>
                ))}
              </div>

              <div className="mt-3">
                <span className="numBedrooms">Bathrooms:</span>
              </div>
              <div className="city-select-thard-section">
                <select
                  className="opan-div-selact-tag"
                  id="numBathrooms"
                  value={numBathrooms}
                  onChange={(e) => {
                    setNumBathrooms(parseInt(e.target.value));
                    generateBathrooms(parseInt(e.target.value));
                  }}
                >
                  {[...Array(11).keys()].slice(1).map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>
              <div id="bathroomsContainer">
                {bathrooms.map(bathroom => (
                  <div key={bathroom.id} style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <h6>Bathroom {bathroom.id}</h6>
                    {bathroom.beds.map((bed, index) => (
                      <div key={bed.id} className="Select_merge_divv mt-3">
                        <div className="city-select-thard-section">
                          <select
                            value={bed.type}
                            onChange={(e) => handleBedTypeChange(bathroom.id, bed.id, e.target.value, true)}
                            className="opan-div-selact-tag"
                          >
                            <option value="" disabled>Select Bathroom Type</option>
                            {bathroomTypes.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="city-select-thard-section">
                          <select
                            value={bed.qty}
                            onChange={(e) => handleBedQtyChange(bathroom.id, bed.id, parseInt(e.target.value), true)}
                            className="opan-div-selact-tag"
                          >
                            <option value="" disabled>Select Quantity</option>
                            {[...Array(11).keys()].slice(1).map(num => (
                              <option key={num} value={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                        {index !== 0 && (
                          <button
                            type="button"
                            onClick={() => removeBed(bathroom.id, bed.id, true)}
                            className="Del_expertise_btnn"
                          >
                            <img src={remove} alt="Remove" />
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addBed(bathroom.id, true)}
                      className="Add_expertise_btnn mt-2"
                    >
                      <div className="button_plus_divv">
                        <img src={plus} alt="Add" />
                        <span className="Adding_more">Add Bathroom</span>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="span-selact-rtag mt-4">
              <span>Cancellation Policy</span>
              {loading ? (
                <span>Loading....</span>
              ) : (
                <div className="Cancellation-radio">
                  {Policies?.map((itm, index) => {
                    {
                      /* console.log(itm) */
                    }
                    return (
                      <div className="redio-input" key={index}>
                        <input
                          type="radio"
                          id="fname"
                          name="policy"
                          checked={itm.id == policyid}
                          // value={values.Policy}
                          value={values.policy}
                          onChange={handle}
                          onBlur={handle}
                          onClick={() => setpolicyid(itm.id)}
                        />

                        <label className="ht-ml-radio" htmlFor="">
                          {itm.policy}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}{" "}
              {errors.policy && touched.policy ? (
                <p className="form-errror">{errors.policy} </p>
              ) : null}
            </div>

            <div className="text-span-div">
              <div className="Write-here-span">
                <span>Neighborhood Description </span>
              </div>
              <textarea
                id="w3review"
                name="Neighborhood"
                value={values.Neighborhood}
                rows="4"
                className="text-area-seconfd mt-2"
                cols="50"
                placeholder="Description"
                onBlur={handleBlur}
                onChange={handle}
              ></textarea>
            </div>
            {errors.Neighborhood && touched.Neighborhood ? (
              <p className="form-errror">{errors.Neighborhood} </p>
            ) : null}

            <div className="text-span-div">
              <div className="Write-here-span">
                <span>Property Description </span>
              </div>
              <textarea
                id="w3review"
                name="Property"
                value={values.Property}
                rows="4"
                className="text-area-seconfd mt-2"
                cols="50"
                placeholder="Description"
                onBlur={handleBlur}
                onChange={handle}
              ></textarea>
            </div>
            {errors.Property && touched.Property ? (
              <p className="form-errror">{errors.Property} </p>
            ) : null}

            <div className="hawy-drayvor">
              <div className="plusbtn">
                <div className="mapmathard">
                  <div className="Amenities-div">
                    <span>Amenities</span>
                  </div>

                  <div>
                    <div className="parentkaparent">
                      <div className="checkboxparent">
                        <span>Bedroom Essentials:</span>
                        {BedRooms.map((item, index) => {
                          // console.log(item);
                          return (
                            <>
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  value={values.bedroomsothors}
                                  // ini---------------------------
                                  name="bedroomsothors"
                                  onClick={() => {
                                    if (item.id === 9) {
                                      setIsoterhbeds(!Isoterhbeds);
                                    }
                                    setBedRooms((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            </>
                          );
                        })}
                        <div>
                          {/* {Isoterhbeds ? (
                            <input
                              value={values.bedroomsothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bedroomsothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null} */}
                          {Isoterhbeds || !!values.bedroomsothors ? (
                            <input
                              value={values.bedroomsothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bedroomsothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        <hr />
                        <div className="checkboxparent">
                          {stepFree.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onChange={handlechange}
                                  onClick={() => {
                                    setStepFree((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                />
                              </div>
                            );
                          })}
                        </div>

                        <hr />
                        <div className="checkboxparent">
                          <span>Surroundings</span>
                          {gardenview.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // ini---------------------------
                                  name="gardenviewothors"
                                  onClick={() => {
                                    if (item.id === 14) {
                                      setIsotherView(!IsotherView);
                                    }
                                    setGardenview((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <div>
                            {/* {IsotherView ? (
                              <input
                                value={values.gardenviewothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="gardenviewothors"
                                className="othars-input-txt"
                                type="text"
                              />
                            ) : null} */}
                            {IsotherView || !!values.gardenviewothors ? (
                              <input
                                value={values.gardenviewothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="gardenviewothors"
                                className="othars-input-txt"
                                type="text"
                              />
                            ) : null}
                          </div>
                          <hr />
                          <span className="Facilities-all">Families</span>
                          {Familie.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onClick={() => {
                                    setfamilie((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <hr />
                          <span className="Facilities-all">Security</span>
                          {security.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  onChange={handlechange}
                                  onClick={() => {
                                    setSecurity((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="checkboxparent">
                        <span>Bathroom Essentials:</span>
                        {Bathroom.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini-------------------
                                name="bathroomothars"
                                onClick={() => {
                                  if (item.id === 444) {
                                    setIsotherBathRooms(!IsotherBathRooms);
                                  }

                                  setBathroom((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {/* {IsotherBathRooms ? (
                            <input
                              value={values.bathroomothars}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bathroomothars"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null} */}
                          {IsotherBathRooms || !!values.bathroomothars ? (
                            <input
                              value={values.bathroomothars}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="bathroomothars"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        <hr />
                        {/* <div className="m"></div> */}
                        <div className="checkboxparent">
                          <span>Air Conditioning</span>
                          {Aircondi.map((item, index) => {
                            return (
                              <div className="checkdivmain" key={index}>
                                <span>{item.txt}</span>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  // ini---------------------------
                                  name="aircondiothors"
                                  onClick={() => {
                                    if (item.id === 7869) {
                                      setAirconditioning(!airconditioning);
                                    }
                                    setAircondi((preval) => {
                                      let temp = [...preval];
                                      let temp2 = temp[index];
                                      temp2 = {
                                        ...temp2,
                                        isSelected: !temp2.isSelected,
                                      };
                                      temp[index] = temp2;
                                      return temp;
                                    });
                                  }}
                                  checked={item.isSelected ? true : false}
                                  onChange={handlechange}
                                />
                              </div>
                            );
                          })}
                          <div>
                            {/* {airconditioning ? (
                              <input
                                value={values.aircondiothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="aircondiothors"
                                className="othars-input-txt"
                                type="text"
                              />
                            ) : null} */}
                            {airconditioning || !!values.aircondiothors ? (
                              <input
                                value={values.aircondiothors}
                                onBlur={handleBlur}
                                onChange={handle}
                                name="aircondiothors"
                                className="othars-input-txt"
                                type="text"
                              />
                            ) : null}
                          </div>
                          <hr />
                          <div className="checkboxparent">
                            <span>Check in method:</span>
                            {checkinMethod.map((item, index) => {
                              return (
                                <div className="checkdivmain" key={index}>
                                  <span className="chack-in-span">
                                    {item.txt}
                                  </span>
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    // ini---------------------------
                                    name="checkinMethodothors"
                                    onClick={() => {
                                      if (item.id === 444) {
                                        setIsotherCheckMethod(
                                          !IsotherCheckMethod
                                        );
                                      }
                                      setCheckinMethod((preval) => {
                                        let temp = [...preval];
                                        let temp2 = temp[index];
                                        temp2 = {
                                          ...temp2,
                                          isSelected: !temp2.isSelected,
                                        };
                                        temp[index] = temp2;
                                        return temp;
                                      });
                                    }}
                                    checked={item.isSelected ? true : false}
                                    onChange={handlechange}
                                  />
                                </div>
                              );
                            })}

                            <div>
                              {" "}
                              {/* {IsotherCheckMethod ? (
                                <input
                                  placeholder=""
                                  className="othars-input-txt"
                                  type="text"
                                  value={values.checkinMethodothors}
                                  onBlur={handleBlur}
                                  onChange={handle}
                                  name="checkinMethodothors"
                                />
                              ) : null} */}
                              {IsotherCheckMethod ||
                                !!values.checkinMethodothors ? (
                                <input
                                  value={values.checkinMethodothors}
                                  onBlur={handleBlur}
                                  onChange={handle}
                                  name="checkinMethodothors"
                                  className="othars-input-txt"
                                  type="text"
                                />
                              ) : null}
                            </div>
                            <div className="m-2"></div>
                            {/* <div className="">
                              <span>Other amenities (Specify) </span>
                              {CheckinMethod.map((item, index) => {
                                return (
                                  <input
                                    type="checkbox"
                                    value={"Other amenities (Specify)"}
                                    onClick={() => {
                                      if (item.id === 76) {
                                        setIsotherCheckMethod1(
                                          !IsotherCheckMethod1
                                        );
                                      }
                                    }}
                                    id={checkboxdata.length}
                                    onChange={handlechange}
                                  />
                                );
                              })}
                            </div> */}
                            {/* <div>
                              {IsotherCheckMethod1 ? (
                                <input type="text" />
                              ) : null}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="checkboxparent">
                        <span>Kitchen:</span>
                        {kitchan.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="kitchanothors"
                                onClick={() => {
                                  if (item.id === 10119) {
                                    setIsotherKichen(!IsotherKichen);
                                  }
                                  setkitchan((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}

                        <div>
                          {IsotherKichen ? (
                            <input
                              name="kitchanothors"
                              value={values.kitchanothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>

                        <div className="m-2"></div>
                        <hr />
                        <b>Space space</b>
                        {sharespace.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                name="amenities"
                                onClick={() => {
                                  if (item.id === 444222) {
                                    setSharespace1(!sharespace1);
                                  }
                                  setsharespace((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {sharespace1 ? (
                            <input className="othars-input-txt" type="text" />
                          ) : null}
                        </div>
                      </div>
                      <div className="checkboxparent">
                        <span>Children:</span>
                        {Child.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="childothors"
                                onClick={() => {
                                  if (item.id === 455) {
                                    setIsotherChildern(!IsotherChildern);
                                  }
                                  setchild((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {/* {IsotherChildern ? (
                            <input
                              value={values.childothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="childothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null} */}
                          {IsotherChildern || !!values.childothors ? (
                            <input
                              value={values.childothors}
                              onBlur={handleBlur}
                              onChange={handle}
                              name="childothors"
                              className="othars-input-txt"
                              type="text"
                            />
                          ) : null}
                        </div>
                        <div></div>
                        <hr />
                        <span className="Facilities-all">Facilities</span>
                        {kitchenette.map((item, index) => {
                          return (
                            <div className="checkdivmain" key={index}>
                              <span>{item.txt}</span>
                              <input
                                type="checkbox"
                                id={item.id}
                                // ini---------------------------
                                name="cleanFessothors"
                                onClick={() => {
                                  if (item.id === 6552) {
                                    setIsOtherCleanFess(!IsOtherCleanFess);
                                  }
                                  setketKitchenette((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                value={"bedsheets"}
                                onChange={handlechange}
                              />
                            </div>
                          );
                        })}
                        <div>
                          {" "}
                          {IsotherKichen1 ? (
                            <input placeholder="Enter CleanFess" type="text" />
                          ) : null}
                        </div>
                        {IsOtherCleanFess ? (
                          <input className="othars-input-txt" type="text" />
                        ) : null}

                        <div>
                          <label className="cleaning-services-Free-in-le">
                            Free cleaning services
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange("0")}
                              onClick={() => setFree(!free)}
                            // checked={selectedCheckBox === 0 ? true : false}
                            // disabled={selectedCheckBox == "checkbox2"}
                            />
                          </label>
                          <br />
                          <label className="cleaning-services-Free-in-le">
                            Cleaning fees
                            <input
                              className="checkbox-ab-se-khrab"
                              type="checkbox"
                              onChange={() => handleCheckboxChange("1")}
                            // checked={selectedCheckBox === 1 ? true : false}

                            // disabled={selectedCheckBox == "checkbox1"}
                            />
                          </label>
                          {showInput ? (
                            <input
                              type="text"
                              value={cleningFee}
                              onChange={(e) => {
                                setCleningFee(e.target.value);
                              }}
                              className="othars-input-txt"
                              placeholder="Cleaning fees"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="between-time mb-2">
                <span className="Check-out-all">Check in time </span>
                <TimePicker onChange={onChangee && onChangee} value={valueee} />
              </div>
              <div className="out-time">
                <span className="Check-out-all"> Check out time_</span>
                <TimePicker onChange={onOut} value={out} />
              </div>
              <div className="noise-time">
                <span className="Check-out-all">No noise after</span>
                <TimePicker onChange={onNoise} value={noise} />
              </div>
              <div className="Swimming-time">
                <span className="Check-out-all">Swimming pool times from</span>
                <TimePicker onChange={onPool} value={pool} />
              </div>
              <div className="tub-time">
                <span className="Check-out-all"> Hot tub times from</span>
                <TimePicker onChange={onTub} value={tubb} />
              </div> */}

              {House.map((item, index) => {
                return (
                  <div className="HouseRules-chake-box" key={index}>
                    <span className="Spann-checkboxx-sett">{item.txt}</span>
                    <input
                      type="checkbox"
                      id={item.id}
                      name="Houseruleshr"
                      onClick={() => {
                        if (item.id === 5023) {
                          setIsotherHouseRules(!IsotherHouseRules);
                        } else if (item.id === 5021) {
                          setDispose(!Dispose);
                        }
                        setHouse((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                  </div>
                );
              })}
              <div>
                {Dispose ? (
                  <input
                    className="othars-input-txt"
                    placeholder="instruction"
                    type="text"
                  />
                ) : null}
              </div>
              <div className="mt-3">
                {IsotherHouseRules ? (
                  <input
                    className="othars-input-txt"
                    placeholder="Others"
                    type="text"
                  />
                ) : null}
              </div>
            </div>
            <div>
              <div className="mt-5">
                <span className="photo-add-span">Property Photos</span>
              </div>
              <div className="mt-3">
                <span className="add-photo-span">Add photos</span>
              </div>
              <div className="add-delet-option">
                <label className="Ht-ml-for" htmlFor={"mal"}>
                  {" "}
                  <img className="mal-te-img" src={Mal} alt="" />
                </label>
                <input
                  id="mal"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  className="Malte-pal-cls"
                />
                {/* <div>
                  <img src={Mal} multiple onChange={handleImageChange} alt="" />
                </div> */}
                <div className="overflowing-add-immgg">
                  {images?.map((image, index) => {
                    let imgURL = URL.createObjectURL(image);

                    return (
                      <div key={index} className="sab-img-ka-div">
                        <img
                          src={imgURL}
                          alt={`Image`}
                          className="add-kiya-huva-pic"
                          style={{ width: "79.71px", height: "79.71px" }}
                        />
                        <button
                          className="Btn-dada-delet"
                          type="button"
                          onClick={() => handimgleDelete(index)}
                        >
                          <svg
                            className="Ma-svg-top"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_25_3172)">
                              <path
                                d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_25_3172">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                  {Images?.map((image, index) => {
                    // console.log(Images, "Images");
                    return (
                      <div key={index} className="sab-img-ka-div">
                        <img
                          src={image.picture_name}
                          value={values.images}
                          alt={`Image-${index}`}
                          className="add-kiya-huva-pic"
                          style={{ width: "79.71px", height: "79.71px" }}
                        />
                        <button
                          className="Btn-dada-delet"
                          type="button"
                          onClick={() => handleAPIimgDelete(image.id)}
                        >
                          <svg
                            className="Ma-svg-top"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_25_3172)">
                              <path
                                d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_25_3172">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="add-prop-btn-div">
              <button type="submit" className="Add-Propertybtn-seco">
                {loading ? "Loading.." : "Edit Property"}
              </button>
            </div>
          </form>
        </div>
      </section>
      {!lod && <div></div>}
      {lod && (
        <div>
          <Backdrop
            sx={{
              color: "#9c04f5",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default EditProperty;
