import React, { useState, useEffect } from "react";
import "../Components/EditProfile.css";
import pic from "../Assets/Images/Editpicuser.png";
import Cemara from "../Assets/Images/CameraImg.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
// import {intSchemas} from "../Schemas";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/Api";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Footer from "../Components/Footer";
import LoginNavbar from "../Components/LoginNavbar";
import aryy from "../Assets/Images/backarry.png";
import Mobile from "../Assets/Images/mobile.png";
import { parsePhoneNumber } from "libphonenumber-js";
import back from "../Assets/Images/Backicon.png";
import tax from "../Assets/Images/tax.png";

const EditProfile = () => {
  const [phone, setPhone] = useState("");

  const [Image, setImage] = useState(null);
  const [userdeta, setuserdeta] = useState([]);
  const [loading, setloading] = useState(false);

  const url =
    "https://admin.athomevacationrentals.online/home/base/static/profile_pic/";
  const token = JSON.parse(localStorage.getItem("login"));
  // console.log(token, "uuuuuuuuu");
  const Getuser = async () => {
    setloading(true);
    try {
      setloading(true);
      const res = await axios.get("/getuser", {
        headers: { authorization: `${token.data.token.token}` },
      });
      if (res.data.status === 1) {
        // console.log("Get-User....", res);
        setloading(false);
        setuserdeta(res.data.data);
      } else {
        setloading(false);
        // console.log("Bhai error aa rahi hai jam k", res);
      }
    } catch (error) {
      // console.log("error hai bhai", error);
      setloading(false);
    }
  };
  useEffect(() => {
    Getuser();
  }, []);

  // console.log(userdeta, " samaj ta hai");

  const initialValues = {
    text: "",
    number: "",
    email: "",
    CountryCode: "",
    Mobile: "",
    host: "",
    texpayer: "",
    texpayerid: "",
  };

  const Navigate = useNavigate();

  const [Loding, setLoding] = useState(false);

  const onSubmit = async () => {
    // console.log("click.....");
    setLoding(true);
    const formdata = new FormData();
    formdata.append("fullname", values.text);
    formdata.append("email", values.email);
    formdata.append("phone_no", values.Mobile);
    formdata.append("image", Image);
    formdata.append("country_code", values.CountryCode);
    formdata.append("language", values.host);
    formdata.append("tax_payer_name", values.texpayer);
    formdata.append("tax_payer_id", values.texpayerid);

    axios({
      method: "post",
      url: "/user/update_profile",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data ",
        Authorization: `${token.data.token.token}`,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setLoding(false);
        // console.log(data, "update");
        localStorage.setItem("login", JSON.stringify(data));
        setuserdeta(data.data === "Sucessfully Updated Profile");
        setloading(false);
        if (data.message === "Sucessfully Updated Profile") {
          Navigate("/MyProfile/PersonalInformation");
        }

        if (data.message === "Sucessfully Updated Profile") {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        if (data.status === 0) {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoding(false);
      });
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });
  // console.log(userdeta.country_code, "phone ");

  useEffect(() => {
    setFieldValue("text", userdeta.fullname);
    setFieldValue("email", userdeta.email);
    setFieldValue("CountryCode", userdeta.country_code);
    setFieldValue("Mobile", userdeta.phone_number);
    setFieldValue("texpayer", userdeta.tax_payer_name);
    setFieldValue("texpayerid", userdeta.tax_payer_id);
  }, [userdeta]);

  const handlePhoneChange = (value) => {
    setPhone(value);
    setFieldValue("number", value); // For Formik validation
    try {
      const parsedPhone =
        value.length >= 6
          ? parsePhoneNumber(value, { defaultCountry: "US" })
          : null;
      if (parsedPhone) {
        setFieldValue("CountryCode", `+${parsedPhone.countryCallingCode}`);
        setFieldValue("Contry", parsedPhone.nationalNumber);
      }
    } catch (error) { }
  };

  return (
    <>
      <LoginNavbar />
      <div>
        <span className="backbtn" onClick={() => Navigate("/MyProfile")}>
          <img src={back} alt="" />
        </span>
      </div>
      <form onSubmit={handleSubmit} className="EditProfile-main-sectiom">
        <div className="EditProfile-main-div">
          <div className="w-100 backbtndiv">
            <span className="pro-span">Edit Profile</span>
          </div>
          {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <div className="second-div mt-4">
              <div className="Ht-ml-for-imput">
                <label className="" htmlFor="mal">
                  <img
                    src={userdeta.profile_pic ? userdeta.profile_pic : pic}
                    alt=""
                    className="pic-on-add"
                  />

                  <img className="Cemara-te-img" src={Cemara} alt="" />
                </label>
                <input
                  id="mal"
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  className="Malte-pal-cls"
                />

                {/* preview Image */}
                {Image && (
                  <img
                    src={Image ? URL.createObjectURL(Image) : ""}
                    // alt="MyProfileImage"
                    width="100px"
                    height="100px"
                    className="add-kiya-muja"
                  />
                )}
              </div>

              <div className="mt-5">
                <span className="">Full Name</span>
                <div className="full-name-bug-div">
                  <div className="text-boxx-fullname mt-2">
                    <div>
                      <svg
                        className="svg-margin"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_25_1583)">
                          <path
                            d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                            fill="#211B24"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_25_1583">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <input
                      className="i-t-a"
                      type="text"
                      placeholder="Ronald Richards"
                      name="text"
                      value={values.text}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_25_1604)">
                      <path
                        d="M6.414 16L16.556 5.85799L15.142 4.44399L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32199C14.6225 2.13452 14.8768 2.02921 15.142 2.02921C15.4072 2.02921 15.6615 2.13452 15.849 2.32199L18.678 5.15099C18.8655 5.33852 18.9708 5.59283 18.9708 5.85799C18.9708 6.12316 18.8655 6.37746 18.678 6.56499L7.243 18ZM3 20H21V22H3V20Z"
                        fill="#211B24"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_25_1604">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="mt-3">
                  <span>Tax Payer Name (Optional)</span>
                </div>
                <div className="mnb-phone mt-2">
                  <div className="drop-book">
                    <img src={tax} alt="" className="svg-margin-added" />
                    <input
                      className="dedra-input"
                      type="text"
                      // placeholder="debra.holt@example.com"
                      name="texpayer"
                      value={values.texpayer}
                      // disabled={true}
                      // style={{ cursor: "not-allowed" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <span>Tax Number (Optional)</span>
                </div>
                <div className="mnb-phone mt-2">
                  <div className="drop-book">
                    <img src={tax} alt="" className="svg-margin-added" />
                    <input
                      className="dedra-input-id"
                      type="text"
                      // placeholder="Tax Payer Id"
                      name="texpayerid"
                      value={values.texpayerid}
                      // disabled={true}
                      // style={{ cursor: "not-allowed" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <span>Mobile</span>
                </div>
                <div className="Property-card-divv-ppx">
                  <div className="input-and-country-merge d-flex justify-content-start">
                    <div className="Ek-svg-hu-me-nahi mt-2">
                      <div>
                        <label htmlFor="Peaky">
                          <img className="Mobile-l-ogo" src={Mobile} alt="" />
                        </label>
                      </div>

                      <div className="Dropdown-divv-select-country">
                        <input
                          type="text"
                          name="CountryCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.CountryCode}
                          disabled={true}
                          style={{ cursor: "not-allowed" }}
                          className="Drop-country-tag"
                        />
                        <input
                          className="dropdown-mobile-input"
                          type="number"
                          name="Mobile"
                          disabled={true}
                          style={{ cursor: "not-allowed" }}
                          value={values.Mobile}
                          onChange={handleChange}
                          placeholder={"Enter Phone number"}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Email</span>
                </div>
                <div className="mnb-phone mt-2">
                  <div className="drop-book">
                    <div>
                      <svg
                        className="svg-margin"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 4.238L10.072 11.338L2 4.216V16H18V4.238ZM2.511 2L10.061 8.662L17.502 2H2.511Z"
                          fill="#211B24"
                        />
                      </svg>
                    </div>
                    <input
                      className="dedra-input"
                      type="email"
                      placeholder="debra.holt@example.com"
                      name="email"
                      value={values.email}
                      disabled={true}
                      style={{ cursor: "not-allowed" }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <div className="host-spann-divv-en mt-3">
                    <span className="host-span-tagg-ger">Host languages</span>
                  </div>
                  <div className="text-boxx-fullname mt-2">
                    <input
                      className="i-t-a-host-itm"
                      type="text"
                      placeholder="Host languages"
                      name="host"
                      value={values.host}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="line-edit"></div>
              </div>

              <div className="mt-4">
                <button type="submit" className="Btn-submit-kare-too">
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
      {!Loding && <div></div>}
      {Loding && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default EditProfile;
